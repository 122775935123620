<template>
  <div>
    <validation-provider
      :name="schema.flabel"
      :rules="schema.validatorCommon"
      v-slot="{ errors }"
      :vid="schema.model"
    >
      <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
        <template slot="label">
          <span
            :style="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('css')
                ? schema.properties.css
                : ''
            "
          >
            <b-icon
              v-if="
                schema.hasOwnProperty('properties') &&
                schema.properties.hasOwnProperty('icon')
              "
              :icon="schema.properties['icon']"
              :size="
                schema.hasOwnProperty('properties') &&
                schema.properties.hasOwnProperty('icon_size')
                  ? 'is-' + schema.properties['icon_size']
                  : 'is-small'
              "
              :style="
                schema.hasOwnProperty('properties') &&
                schema.properties.hasOwnProperty('icon_color')
                  ? 'color:' + schema.properties['icon_color']
                  : ''
              "
            >
            </b-icon>
            {{ labelHandler }}
          </span>
          <span class="has-text-danger" v-if="checkRequired">*</span>
        </template>
        <b-field>
          <p
            v-if="schema.showPreviousValue"
            class="control previous-value-control"
          >
            <b-button :label="String(oldValue)" disabled expanded />
          </p>
          <b-datepicker
            :ref="`datepicker-${schema.id}`"
            expanded
            size="is-small"
            :nearby-selectable-month-days="true"
            :disabled="disabled"
            :date-formatter="formatterFunction"
            v-model="value"
            :placeholder="schema.placeholder"
            icon="calendar-today"
            trap-focus
            :editable="editable"
            :data-cy="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('data-cy')
                ? schema.properties['data-cy']
                : ''
            "
          >
            <b-button
              type="is-danger"
              icon-left="close"
              outlined
              @click="dateValue = null"
            >
              {{ $t('clear') }}
            </b-button>
          </b-datepicker>
        </b-field>
      </b-field>
    </validation-provider>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { datePickerModalExpander } from '@/services/utilities.js'
import { useAppStore } from '@/stores/app'
import { callRunDependency } from '@/services/form-builder'

export default {
  mixins: [abstractField],
  components: {
    ValidationProvider
  },
  setup() {
    const appStore = useAppStore()
    const { dateConfig, clientKey } = storeToRefs(appStore)

    return { dateConfig, clientKey }
  },
  data() {
    return {
      oldValue: '',
      position: 'is-bottom-right',
      callRunDependency
    }
  },
  created() {
    if (this.value) {
      this.value = new Date(this.value)
    }
    this.oldValue = this.formatterFunction()
    callRunDependency(this.schema, this.model, this.value, true)
  },
  methods: {
    formatterFunction() {
      if (this.dateConfig.dateFormatJs) {
        return this.dateConfig && this.dateConfig.dateFormatJs
          ? moment(this.value).format(this.dateConfig.dateFormatJs)
          : moment(this.value).format('YYYY-MM-DD')
      }
      return this.dateConfig && this.dateConfig.dateFormat
        ? moment(this.value).format(this.dateConfig.dateFormat)
        : moment(this.value).format('YYYY-MM-DD')
    }
  },
  mounted() {
    datePickerModalExpander()
  },
  computed: {
    // ...mapState(['dateConfig']),
    editable() {
      // This relates to card FNG-492 - needs to be fixed
      if (
        this.clientKey === 'J8qZMl6Y0ObBC4AZus6LiGxqEhX0Mz28' ||
        this.$clientKey === 'vxJZrNWzh1eQosHQmwemxLdmgiJJMnQx'
      ) {
        return false
      }
      return true
    },
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    }
  },
  watch: {
    value() {
      if (this.value && typeof this.value.getMonth !== 'function') {
        this.value = new Date(this.value)
      }
      callRunDependency(this.schema, this.model, this.value)
    }
  }
}
</script>
